<template>
  <div class="main-wrapper">
    <video muted autoplay loop class="intro-video">
      <source src="/assets/videos/widget/installation.mp4" type="video/mp4" />
    </video>
    <span>Connect Zendesk to Macha AI</span>
    <h3>Start Using AI to Respond to Tickets</h3>
    <p>
      Connect your Zendesk with Macha and use AI to create replies with simple
      instructions, help center articles, past tickets and more
    </p>
  </div>
</template>
    
<script>
export default {
  name: "ZendeskInstallation",
  data() {
    return {};
  },
  components: {},
  methods: {},
};
</script>
    
<style scoped lang="scss">
.main-wrapper {
  text-align: center;

  span {
    display: block;
    font-size: $smallerFontSize;
    font-weight: $mediumFontWeight;
    color: $purpleColor;
    margin-top: 1rem;
  }

  h3 {
    font-size: 1.35rem;
  }

  p {
    font-size: $smallFontSize;
    width: 80%;
    margin: 1rem auto 0;
  }
}

.intro-video {
  width: 65%;
}
</style>