<template>
  <div class="main-wrapper">
    <VueSlickCarousel :arrows="true" :dots="true" dotsClass="dots-wrapper">
      <div>
        <video muted autoplay loop class="intro-video">
          <source src="/assets/videos/widget/tutorial-2.mp4" type="video/mp4" />
        </video>
        <span>Start using AI today</span>
        <h3>Just hit generate for an AI answer</h3>
        <p>
          Is the answer to your ticket in your help center? Just hit the
          'Generate' button and the AI will create a response from your help
          center. Just one click!
        </p>
        <a
          href="https://www.getmacha.com/blog/how-to-best-use-macha-ai-zendesk-agent-assist-widget/"
          target="_blank"
          class="btn btn-primary"
          >Best ways to use AI for replies</a
        >
      </div>
      <div>
        <video muted autoplay loop class="intro-video">
          <source src="/assets/videos/widget/tutorial-1.mp4" type="video/mp4" />
        </video>
        <span>AI now available on your account</span>
        <h3>Create responses with short instructions</h3>
        <p>
          Just give a short instruction to the AI, and it’ll create a complete
          response for you. The response will include greetings, answer from
          instructions and closing salutations.
        </p>
        <a
          href="https://www.getmacha.com/blog/how-to-best-use-macha-ai-zendesk-agent-assist-widget/"
          target="_blank"
          class="btn btn-primary"
          >Best ways to use AI for replies</a
        >
      </div>
      <div>
        <video muted autoplay loop class="intro-video">
          <source src="/assets/videos/widget/tutorial-3.mp4" type="video/mp4" />
        </video>
        <span>AI now available on your account</span>
        <h3>Enrich answers with help center articles</h3>
        <p>
          Want to share a policy or provide a help center article link? Just
          instruct the AI to add it. Say goodbye to search + copy + paste
        </p>
        <a
          href="https://www.getmacha.com/blog/how-to-best-use-macha-ai-zendesk-agent-assist-widget/"
          target="_blank"
          class="btn btn-primary"
          >Best ways to use AI for replies</a
        >
      </div>

      <template #prevArrow>
        <unicon name="angle-left" class="arrow-left"></unicon>
      </template>
      <template #nextArrow>
        <unicon name="angle-right" class="arrow-right"></unicon>
      </template>
    </VueSlickCarousel>
  </div>
</template>
      
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  name: "WidgetTutorial",
  data() {
    return {};
  },
  components: { VueSlickCarousel },
  methods: {},
};
</script>
      
  <style scoped lang="scss">
.main-wrapper {
  text-align: center;

  span {
    display: block;
    font-size: $smallerFontSize;
    font-weight: $mediumFontWeight;
    color: $purpleColor;
    margin-top: 1rem;
  }

  h3 {
    font-size: 1.35rem;
  }

  p {
    font-size: $smallFontSize;
    width: 80%;
    margin: 1rem auto 0;
  }
}

.intro-video {
  width: 55%;
}

:deep(.dots-wrapper) {
  margin: 2rem 0 0;

  li {
    display: inline-block;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background: #cecece;
    margin: 0 0.25rem;
    cursor: pointer;
    border: 2px solid lighten(#cecece, 5%);

    &.slick-active {
      background: $purpleColor;
      border: 2px solid lighten($purpleColor, 5%);
    }

    button {
      display: none;
    }
  }
}

.arrow-left,
.arrow-right {
  position: absolute;
  top: 5rem;
  left: 0.5rem;
  cursor: pointer;
  z-index: 5;

  :deep(svg) {
    height: auto;
    fill: #ccc;
    width: 3rem;

    &:hover {
      fill: $purpleColor;
    }
  }
}

.arrow-right {
  left: auto;
  right: 0.5rem;
}

.btn {
  display: inline-block;
  font-size: $smallFontSize;
  margin-top: 1rem;
}
</style>