<template>
  <div class="selectors-wrapper">
    <div class="settings-form card">
      <div class="content-wrapper">
        <h3>Advanced Settings</h3>
        <p>Precisely chunk your data by using HTML selectors</p>
      </div>

      <!--START: Chunks-->
      <div class="settings-item">
        <FormBuilder
          :fields="{ field: toggleFields.chunks }"
          @fieldChanged="fieldChanged"
        ></FormBuilder>
        <FormBuilder
          v-if="toggleFields.chunks.value"
          :fields="{ field: toggleFields.useAxios }"
          @fieldChanged="fieldChanged"
        ></FormBuilder>
        <div v-if="toggleFields.chunks.value">
          <div
            v-for="(chunk, i) in selectorFields.chunks"
            :key="chunk.id"
            class="chunk-field"
          >
            <FormBuilder
              :fields="{ field: chunk }"
              class="settings-item-form chunk-form"
              @fieldChanged="fieldChanged"
            ></FormBuilder>
            <unicon
              v-if="selectorFields.chunks.length > 1"
              name="times"
              @click="removeChunkSelector(i)"
            ></unicon>
          </div>

          <!--START: Add Chunk-->
          <button
            class="btn btn-text btn-small btn-icon btn-no-padding btn-add-chunk"
            @click="addChunkSelector"
          >
            <unicon name="plus"></unicon>
            <span>Add Chunk</span>
          </button>
          <div class="clear"></div>
          <!--END: Add Chunk-->
        </div>
      </div>
      <!--END: Chunks-->
    </div>
  </div>
</template>
        
  <script>
//Import libraries
import _ from "lodash";

// Importing components
import FormBuilder from "@/components/form/FormBuilder";

export default {
  name: "ShopifyOptions",
  data() {
    return {
      showOptions: {},
      toggleFields: {
        chunks: {
          type: "toggle",
          title: "Add CSS selectors for specific data",
          additionalClass: "large-toggle",
          value: false,
        },
        useAxios: {
          type: "toggle",
          title: "Is content injected using Javascript?",
          additionalClass: "large-toggle",
          value: false,
        },
      },
      selectorFields: {
        descriptionDelimiter: {
          type: "text",
          placeholder: "HTML selector to split descriptions",
          required: false,
          hasError: false,
          title: "Description splitter",
          value: "",
        },
        chunks: [],
      },
      chunkField: {
        type: "text",
        placeholder: "Comma-separated HTML selectors for a new chunk",
        required: false,
        hasError: false,
        title: "Chunk selectors",
        value: "",
      },
    };
  },
  props: {},
  watch: {},
  components: {
    FormBuilder,
  },
  computed: {},
  async created() {
    this.initSelectors();
  },
  methods: {
    // Initialise the selectors
    initSelectors() {
      this.addChunkSelector();
    },

    // Adding a chunk selector
    addChunkSelector() {
      const chunkSelector = _.cloneDeep(this.chunkField);
      this.selectorFields.chunks.push(chunkSelector);
    },

    // Remove a chunk selector
    removeChunkSelector(i) {
      this.selectorFields.chunks.splice(i, 1);
    },

    // Event when field changed
    fieldChanged() {
      // Extract the data
      const { chunks: hasChunks, useAxios } = this.toggleFields;
      const { chunks } = this.selectorFields;

      // Creating the payload
      const payload = {};

      // Add the chunks
      let chunkSelectors = [];
      if (hasChunks.value) {
        payload.useAxios = !useAxios.value;
        chunks.forEach((c) => {
          chunkSelectors.push(c.value);
        });
        payload.chunkSelectors = chunkSelectors;
      }

      this.$emit("optionsChanged", payload);
    },
  },
};
</script>
        
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.selectors-wrapper {
  width: 100%;

  .settings-form.card {
    padding-bottom: 1rem;
    margin-bottom: 0;
  }

  /deep/ {
    .field-wrapper {
      min-width: 50% !important;
    }
  }

  .content-wrapper {
    width: auto;
  }
}

.settings-item {
  margin-bottom: 0.75rem;
  border-bottom: 1px solid $greyBorderColor2;

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
}

.settings-item-form {
  /deep/ {
    .field-title {
      font-weight: $normalFontWeight !important;
      opacity: $lightOpacity !important;
    }
  }
}
.chunk-field {
  display: flex;
  flex-direction: row;
  align-items: center;

  .chunk-form {
    flex: 1;
  }

  /deep/ .unicon {
    svg {
      display: block;
      fill: $greyColor;
      margin-left: 0.5rem;
      width: 1.25rem;
      height: auto;
      margin-top: -0.5rem;
      cursor: hover;

      &:hover {
        cursor: hover;
        fill: $redColor;
      }
    }
  }
}
.btn-add-chunk {
  float: right;
  margin-bottom: 1.5rem;
}
</style>