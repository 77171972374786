<template>
  <div class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->
      <FormBuilder :fields="fields" @fieldChanged="fieldChanged"></FormBuilder>
    </div>
    <!--END: Header Wrapper-->

    <div
      v-if="!fetchingInsights"
      ref="insights"
      class="insights-main"
      @scroll="insightsScroll"
    >
      <ViewInsights :insights="insights"></ViewInsights>
    </div>

    <!--START: Pre Loader-->
    <div v-else class="pre-loader-wrapper">
      <PreLoader :show="true" v-for="index in 2" :key="index"></PreLoader>
    </div>
    <!--END: Pre Loader-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
            
<script>
// Import libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import NotificationMessage from "@/components/modals/NotificationMessage";
import PreLoader from "@/components/loaders/PreLoader";
import LineLoader from "@/components/loaders/LineLoader";
import FormBuilder from "@/components/form/FormBuilder";

import ViewInsights from "@/components/dashboard/analytics/ViewInsights";

// Importing Services
import { AnalyticsService } from "@/services";

// Importing Services
import "external-svg-loader";

export default {
  name: "Insights",
  data() {
    return {
      lodash: _,
      showOptions: {
        headerShadow: false,
        unsavedChanges: false,
        lineLoader: false,
        disableButton: true,
      },
      date: {
        month: this.moment().format("MMMM"),
        year: this.moment().format("YYYY"),
      },
      fields: {
        date: {
          type: "dropdown",
          title: "Select date range",
          placeholder: "Select date",
          required: true,
          hasError: false,
          fields: [],
          value: [],
        },
      },
      fetchingInsights: true,
      insights: {},
      regularHeader: {
        title: "Insights",
        description: "Analytics into the usage of the AI assistant widget",
      },

      status: {
        show: false,
        status: "success",
        title: "Widget theme saved",
      },
    };
  },
  props: {},
  components: {
    RegularHeader,
    ViewInsights,
    NotificationMessage,
    LineLoader,
    PreLoader,
    FormBuilder,
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
  },
  watch: {},
  async created() {
    this.initDates();
    await this.getInsights();
  },
  methods: {
    // Initialise the dates array
    initDates() {
      const createdDate = this.moment(this.company.createdAt);
      const currentDate = this.moment();

      for (
        var m = this.moment(createdDate);
        m.diff(currentDate, "month") <= 0;
        m.add(1, "month")
      ) {
        const option = {
          name: `${m.format("MMMM")} ${m.format("YYYY")}`,
          value: {
            month: m.format("MMMM"),
            year: m.format("YYYY"),
          },
        };
        this.fields.date.fields.push(option);
      }

      this.fields.date.value = [
        `${currentDate.format("MMMM")} ${currentDate.format("YYYY")}`,
        {
          month: currentDate.format("MMMM"),
          year: currentDate.format("YYYY"),
        },
      ];
    },

    // Get insights
    async getInsights() {
      this.fetchingInsights = true;
      const data = {
        start: this.date,
        end: this.date,
      };

      const response = await AnalyticsService.GetInsights(data);
      if (!response.hasError) this.insights = response.data;

      this.fetchingInsights = false;
    },

    // Refresh the insights
    refreshInsights(dateRange) {
      this.dateFilter = dateRange;
      this.getInsights();
    },

    // Event on settings scroll
    insightsScroll() {
      const scrollTop = this.$refs.insights.scrollTop;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },

    // Date range has been updated
    fieldChanged() {
      const date = this.fields.date.value[1];
      this.date = { month: date.month, year: date.year };
      this.refreshInsights();
    },
  },
};
</script>
          
<style scoped lang="scss">
.header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid darken($rowBorderColor, 2.5%);
  position: relative;
  z-index: 2;

  .regular-header {
    flex: 1;
    max-width: 70%;
    margin-right: 30%;
  }
}

.insights-main {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow-y: scroll;
  padding: 1.5rem 1rem 0;
  flex: 1;
}

.page-loader-wrapper {
  margin-top: 2rem;
}

/deep/ .line-loader {
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 0;
}

.show-shadow {
  box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
}

.pre-loader-wrapper {
  flex: 1;
  margin: 5rem;

  /deep/ {
    .buffer-line {
      width: auto;
    }
    .buffer-category {
      display: none;
    }

    .pre-loader:first-child {
      margin-bottom: 2rem;
      .buffer-category {
        display: block;
      }
    }
  }
}

/deep/ {
  .line-loader {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  .field-main-item {
    margin-bottom: 0;
  }

  .field-title {
    display: block;
    font-size: $smallestFontSize;
    text-align: right;
    margin-right: 0.5rem;
  }

  .dropdown-info-wrapper {
    margin-top: -0.5rem;
    background-color: $darkBlackColor;

    .field-placeholder {
      font-size: $smallFontSize;
      color: darken($whiteColor, 10%) !important;
      width: max-content;
      margin-right: 1.5rem;
    }

    .unicon svg {
      fill: darken($whiteColor, 30%);
    }
  }
}
</style>