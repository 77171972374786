<template>
  <div>
    <!--START: App Router View-->
    <keep-alive>
      <router-view :key="$route.params.id" />
    </keep-alive>
    <!--END: App Router View-->
  </div>
</template>
  
<script>
export default {
  name: "ZendeskViews",
  data() {
    return {};
  },
  components: {},
  async created() {},
};
</script>
  
  <style>
</style>